.layoutWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.navbar {
  background-color: var(--background-color);
  padding: 0 1.3rem;
  width: 100%;
}

.navbar > div > div:first-child {
  width: 250px;
}

.brand {
  display: flex;
  align-items: center;
}

.brand .logo {
  width: 4.8rem;
  height: 4.8rem;
}

.brand .logoText {
  font-size: 1.6rem;
  font-family: "dm-bold", sans-serif;
  margin-left: 0.5rem;
  color: var(--primary-color);
}

.contentWrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.mainContent {
  flex: 1;
  overflow-y: auto;
  background-color: var(--secondary-color);
}

.sideBar {
  background-color: var(--secondary-color);
}

.sidebarToggle svg {
  transition: transform 0.3s ease-in-out;
}

.collapsed.sidebarToggle svg {
  /* Flip horizontally */
  transform: scaleX(-1);
}
