.searchBar {
  flex-grow: 1;
  min-width: 300px;
  max-width: 500px;
  margin: 0;
  margin-left: auto;
}
.searchBar.secondary .searchInput,
.searchBar.secondary .searchButton {
  background-color: #fff;
  border-color: #d7d7d7;
}
.searchBar.secondary .searchInput {
  padding: 0.5rem;
  border-radius: 10px;
}

.searchInput {
  width: 100%;
  border-radius: 50px;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  font-family: "dm-regular", sans-serif;
  padding: 0.8rem;
  padding-left: 0.5rem;
}

.searchInput:focus {
  box-shadow: none;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
  border-left-width: 0;
}

.searchButton::placeholder {
  font-family: "dm-regular", sans-serif;
  color: var(--placeholder-color);
}
.secondary .searchButton {
  border-radius: 10px;
}
.searchButton {
  border-radius: 50px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  padding-right: 0;
}

.searchButton:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.searchIcon {
  width: 1.4rem;
  height: 1.4rem;
}
.secondary .searchIcon {
  width: 1rem;
  height: 1rem;
}
