:root {
  --primary-color: #233951 !important;
  --secondary-color: #eff3fb;
  --placeholder-color: #9aa5b4;
  --border-color: red;
  --tertiary-color: #b5b5b5;
  --error-color: #e62e2e;
  --green-color1: #18b368;
  --sky-blue: #39b6d2;
  --purple: #6739d2;
  --light-blue: #e8eeff;
  --green: #069952;
  --light-green: #f5fdff;
  --red-color: #cc0000;
  --light-red: rgba(255, 230, 230, 0.6);
  --placeholder-color: #838799;
  --text-color: #141519;
  --text-color-secondary: #1e2837;
  --text-color-gray: #8f949b;
  --secondary-text-color: #767676;
  --heading-color: #292b33;
  --white-color: #fff;
  --icon-color: #6b6f80;
  --icon-border-color: #aeb8c7;
  --disabled-bg: #edeef2;
  --disabled-color: #9ea2b3;
  --disabled-input-bg-color: #eff3fb;
  --field-border-radius: 4px;
  --secondary-gray-color: #dcdee6;
  --toast-progress: #174fdf;
  --screen-bg: #f7fafc;
  --pink: #e1d6f9;
  --light-pink: #f8f5ff;
  --violet: #5422c6;
  --frostedLilac: #e8eeff;
  --warning-color: #ff9966;
  --success-color: #33b3bb;
  --danger-color: #ff5358;
  --primary-outline-color: #e2ecff;
  --primary-outline-text-color: #397aff;
  --card-bg-white: #fff;
  --card-border-color: #e2ecff;
  --font-family-regular: "dm-regular", sans-serif;
  --font-family-medium: "dm-medium", sans-serif;
  --font-family-bold: "dm-bold", sans-serif;
  --stats-card-heading-color: #09090b;
  --stats-card-count-color: #09090b;
  --stats-card-percent-change-color: #1cb02e;
}

html,
body {
  font-family: "dm-regular", sans-serif;
}
ul,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
a,
svg {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

input {
  outline: none;
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Firefox / */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  outline: none;
  border: none;
}

/* == describe fonts == */

/*Regular (normal): 400
  Medium: 500
  SemiBold: 600
  Bold: 700*/
@font-face {
  /* fw-400 */
  font-family: "dm-regular";
  src: url("../src/assets/fonts/DMSans-Regular.ttf");
}
@font-face {
  /* fw-500 */
  font-family: "dm-medium";
  src: url("../src/assets/fonts/DMSans-Medium.ttf");
}
@font-face {
  /* fw-600 */
  font-family: "dm-semiBold";
  src: url("../src/assets/fonts/DMSans-SemiBold.ttf");
}
@font-face {
  /* fw-700 */
  font-family: "dm-bold";
  src: url("../src/assets/fonts/DMSans-Bold.ttf");
}
/* === utility classes for font family ===*/
.fw-400 {
  font-family: "dm-regular";
}
.fw-500 {
  font-family: "dm-medium";
}
.fw-600 {
  font-family: "dm-semiBold";
}

.fw-700 {
  font-family: "dm-bold";
}
p,
span {
  font-family: "dm-regular";
}

.pt-26 {
  padding-top: 26px;
}
.errorText {
  color: var(--red-color);
  font-size: 14px;
  line-height: 20px;
  padding-top: 5px;
  font-family: "dm-regular";
  text-transform: capitalize;
  margin: 0;
}
.steric {
  color: var(--red-color);
  font-family: "dm-semiBold";
}
.link {
  text-decoration: underline;
  color: var(--primary-color) !important;
  font-size: 14px;
  cursor: pointer;
  font-family: "dm-regular";
}
.cp {
  cursor: pointer;
}
/* Custom styles for react-phone-input-2 */
.form-control:focus {
  box-shadow: initial !important;
}

/* adjus text in line */
.text-control {
  text-decoration: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* not needed even though some documentation says it is
  text-overflow: ellipsis; */
}

.loader-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000; /* Assuming a dark background */
}

.pulse {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: dash 2s linear infinite;
}

.scrollbar1::-webkit-scrollbar {
  width: 1px;
  height: 6px;
}

.scrollbar1::-webkit-scrollbar-track {
  border-radius: 8px;
  /* background-color: #e7e7e7;
  border: 1px solid #cacaca; */
  background-color: #fff;
  border: 1px solid #fff;
}

.scrollbar1::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--primary-color);
}

button:active {
  border-color: transparent !important;
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.nav-item .nav-link {
  padding: 20px 24px !important;
  color: var(--icon-color) !important;
  font-family: "dm-semiBold";
  border-radius: 8px !important;
}

.nav-item .nav-link.active {
  background-color: var(--white-color) !important;
  color: var(--heading-color) !important;
  font-family: "dm-semiBold";
  line-height: 24px;
  font-size: 16px;
  text-align: center;

  border-radius: 8px 8px 0 0 !important;
}
.nav-pills {
  background-color: var(--screen-bg) !important;
}
.nav-tabs {
  border-bottom: 0px !important;
  margin-bottom: 0 !important;
}

.popover-content {
  background: white;
  border-radius: 4px;
  padding: 4px 4px;
  width: fit-content;
}

.btn-container {
  cursor: pointer;
}
.popover-content {
  background-color: var(--white-color);
  border-radius: 0.5rem;
  font-size: 0.875rem;

  z-index: 9999;
  background: #ffffff;
  position: relative;
}

.popover-content.bg-white {
  background-color: #fff;
}

.no-library .popover-content {
  position: absolute;
  margin-top: 0.5rem;
}

.btn-container {
  display: flex;
  align-items: center;
}

.react-tiny-popover-container {
  z-index: 9999 !important;
}
.EZDrawer__overlay {
  z-index: 99999 !important;
}

.EZDrawer__container {
  z-index: 999999 !important;
}
.unit {
  font-size: 16px;
  font-family: "dm-semiBold";
  line-height: 24px;
}
@media (max-width: 515px) {
  .EZDrawer__container {
    width: 100% !important;
  }
}

.link {
  font-family: "dm-semiBold";
  color: var(--primary-color);
  line-height: 18px;
}
.no-scroll {
  overflow: hidden;
}
.Toastify__toast-icon {
  z-index: 1000;
}
.Toastify__toast-icon {
  display: none !important;
}

.modal-backdrop {
  z-index: 1000 !important;
}
.modal {
  z-index: 1000;
}
.p-datepicker {
  border: none !important;
}
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-year:enabled:hover,
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month:enabled:hover {
  color: var(--primary-color) !important;
}
.p-datepicker table td > span.p-highlight {
  color: #233951 !important;
  background: #eef2ff;
}
.p-datepicker-header {
  padding: 1rem;
}
.p-datepicker-calendar thead th span {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-datepicker-calendar-container {
  padding: 0.5rem;
}
.p-inputtext.p-component.p-inputtext.p-component.p-disabled {
  color: var(--primary-color);
  background-color: var(--disabled-input-bg-color);
  border-color: #cdcdcd;
}
.custom-select {
  border-color: #cdcdcd !important;
  border-radius: 10px !important;
  padding: 17px 20px !important;
}
.custom-select:disabled {
  background-color: var(--disabled-input-bg-color) !important;
}

.p-disabled,
.p-component:disabled {
  opacity: 1 !important;
}

.p-image-preview-indicator {
  border-radius: 25px !important;
}

.p-tooltip .p-tooltip-text {
  padding: 1rem !important;
  width: 60%;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  padding: 0.3rem 1rem;
  width: auto;
  font-family: "dm-semiBold", sans-serif;
  border-radius: 4px;
}
.p-confirm-dialog-accept.p-button.p-component {
  background: var(--success-color);
  border: 1px solid var(--success-color);
}
.p-confirm-dialog-reject.p-button.p-component {
  background: var(--error-color);
  border: 1px solid var(--error-color);
  color: var(--white-color);
}
.p-datatable .p-datatable-loading-overlay {
  z-index: 99 !important;
}
.p-menu-list {
  border-radius: 8px;
  margin: 0 !important;
  padding: 0.31rem !important;
}
.p-menuitem-link {
  font-family: "dm-semiBold", sans-serif;
  font-size: 14px;
  text-decoration: none !important;
  margin-bottom: 10px;
  padding: 0.5rem 1rem;
}
.text-italic {
  font-style: italic;
}
