.circledButton {
  min-width: 45px;
  min-height: 45px;
  border-radius: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  transition: all 0.3s ease;
}

.circledButton:hover,
.circledButton:focus,
.circledButton:active {
  background-color: var(--secondary-color) !important;
  border-color: var(--icon-border-color) !important;
  box-shadow: none !important;
}

.icon {
  width: 20px;
  height: 20px;
}
.circledButton.small {
  min-width: 35px;
  min-height: 35px;
  padding: 0.5rem;
}
.circledButton.small .icon {
  width: 15px;
  height: 15px;
}
