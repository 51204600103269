.sidebar {
  width: 330px;
  transition: width 0.3s ease;
  overflow-x: hidden;
  border-top: 1px solid var(--tertiary-color);
  padding-top: 1rem;
}

.sidebarNav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  height: calc(100%);
}

.collapsed {
  width: 105px;
}

.sidebarNav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navLink {
  display: flex;
  align-items: center;
  padding: 1rem;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
  color: var(--text-color-secondary);
  text-decoration: none;
  transition: all 0.3s ease;
  background-color: transparent;
  border-radius: 50px;
  margin: 1rem;
  height: 70px;
}

.navLink.active {
  background-color: var(--primary-color);
  color: #fff;
}

.navLink:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.navLink.active .icon svg path,
.navLink:hover .icon svg path {
  fill: #fff;
}

.icon {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.text {
  font-family: "dm-regular", sans-serif !important;
  white-space: nowrap;
}

.linkButton {
  margin-left: auto;
  background-color: hsla(0, 0%, 100%, 0.15) !important;
  border-color: transparent !important;
  opacity: 0;
}

.linkButton:hover,
.linkButton:active,
.linkButton:focus {
  background-color: hsla(0, 0%, 100%, 0.26) !important;
  border-color: hsla(0, 0%, 100%, 0.26) !important;
}

.navLink:hover .linkButton,
.navLink.active .linkButton {
  opacity: 1;
}

.userActions {
  margin-top: auto;
}

.divider {
  border: none;
  border-top: 1px solid var(--tertiary-color);
  margin: 1rem 0;
}

.userRole {
  font-family: "dm-regular", sans-serif !important;
  font-size: 1.2rem;
  padding: 1rem;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
  margin: 1rem;
  color: var(--text-color-gray);
}

.userInfo {
  display: flex;
  align-items: center;
  color: var(--text-color-secondary);
  text-decoration: none;
  transition: all 0.3s ease;
  background-color: transparent;
  border-radius: 50px;
  height: 70px;
  gap: 1rem;
  margin: 1rem;
  /* border: 1px solid var(--tertiary-color); */
}

.collapsed .userInfo {
  background-color: var(--primary-color);
}

.userAvatar {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.userAvatar span {
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
}

.userName {
  font-family: "dm-semibold", sans-serif !important;
  font-size: 1.2rem;
  color: var(--text-color-secondary);
}

.profileImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
